<template>
  <div>
    <Briefing :briefingId="briefingId" :roundBriefing="true" />
  </div>
</template>

<script>
import Briefing from "@/views/pages/company/index/formBriefing/AddCompany";
import { mapState } from "vuex";

export default {
  name: "BriefingComponent",
  components: {
    Briefing,
  },
  computed: {
    ...mapState(["round"]),
  },
  async created() {
    this.briefingId = this.$route.params.briefingId;
  },
  data() {
    return {
      briefingId: null,
    };
  },
};
</script>

<style scoped></style>
